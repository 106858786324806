// extracted by mini-css-extract-plugin
export var cancelled = "ProjectProposalStatusDropdown__cancelled__YeFcB";
export var changing = "ProjectProposalStatusDropdown__changing__dALRk";
export var closeOverlayButton = "ProjectProposalStatusDropdown__closeOverlayButton__PMMGi";
export var column = "ProjectProposalStatusDropdown__column__g8v6O";
export var description = "ProjectProposalStatusDropdown__description__tc9WX";
export var dropdownBody = "ProjectProposalStatusDropdown__dropdownBody__zMHuR";
export var dropdownBodyRow = "ProjectProposalStatusDropdown__dropdownBodyRow__Nixj7";
export var expired = "ProjectProposalStatusDropdown__expired__CIXyZ";
export var finalized = "ProjectProposalStatusDropdown__finalized__BGPwf";
export var inProgress = "ProjectProposalStatusDropdown__inProgress__WWwRT";
export var label = "ProjectProposalStatusDropdown__label__HeAxj";
export var message = "ProjectProposalStatusDropdown__message__bIm19";
export var modalMessageButtons = "ProjectProposalStatusDropdown__modalMessageButtons__B73pZ";
export var modalMessageOverlay = "ProjectProposalStatusDropdown__modalMessageOverlay__HWXPx";
export var nobid = "ProjectProposalStatusDropdown__nobid__F0Gn5";
export var pillTitle = "ProjectProposalStatusDropdown__pillTitle__yFGQS";
export var preparing = "ProjectProposalStatusDropdown__preparing__zzCuY";
export var preparingCursor = "ProjectProposalStatusDropdown__preparingCursor__BWbMe";
export var proposalDropdownTrigger = "ProjectProposalStatusDropdown__proposalDropdownTrigger__crfU3";
export var proposalModalMessageContainer = "ProjectProposalStatusDropdown__proposalModalMessageContainer__ampbI";
export var proposalModalMessageText = "ProjectProposalStatusDropdown__proposalModalMessageText__kE6W6";
export var row = "ProjectProposalStatusDropdown__row__vaIfW";
export var statusMessageButtons = "ProjectProposalStatusDropdown__statusMessageButtons__QeuGu";
export var statusMessageModalContainer = "ProjectProposalStatusDropdown__statusMessageModalContainer__sfwvZ";
export var statusMessageModalContent = "ProjectProposalStatusDropdown__statusMessageModalContent__BhyPl";